var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "오더내역" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        editable:
                          _vm.editable && !_vm.disabled && !_vm.apprDisabled,
                        isFlag: true,
                        label: "고장여부",
                        name: "breakdownFlag",
                      },
                      model: {
                        value: _vm.data.breakdownFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "breakdownFlag", $$v)
                        },
                        expression: "data.breakdownFlag",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable:
                          _vm.editable && !_vm.disabled && _vm.breakdowned,
                        rows: 1,
                        label: "고장내역",
                        name: "breakRemark",
                      },
                      model: {
                        value: _vm.data.breakRemark,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "breakRemark", $$v)
                        },
                        expression: "data.breakRemark",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        editable:
                          _vm.editable &&
                          !_vm.disabled &&
                          !_vm.apprDisabled &&
                          _vm.breakdowned,
                        codeGroupCd: "WO_BD_PHENOMENON_CD",
                        valueText: "codeName",
                        valueKey: "code",
                        label: "현상",
                        name: "phenomenons",
                        inline: false,
                      },
                      model: {
                        value: _vm.data.phenomenons,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "phenomenons", $$v)
                        },
                        expression: "data.phenomenons",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        editable:
                          _vm.editable &&
                          !_vm.disabled &&
                          !_vm.apprDisabled &&
                          _vm.breakdowned,
                        codeGroupCd: "WO_BD_CAUSE_CD",
                        valueText: "codeName",
                        valueKey: "code",
                        label: "원인",
                        name: "causes",
                        inline: false,
                      },
                      model: {
                        value: _vm.data.causes,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "causes", $$v)
                        },
                        expression: "data.causes",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        editable:
                          _vm.editable &&
                          !_vm.disabled &&
                          !_vm.apprDisabled &&
                          _vm.breakdowned,
                        codeGroupCd: "WO_BD_ACTION_CD",
                        valueText: "codeName",
                        valueKey: "code",
                        label: "조치",
                        name: "actions",
                        inline: false,
                      },
                      model: {
                        value: _vm.data.actions,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "actions", $$v)
                        },
                        expression: "data.actions",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable:
                          _vm.editable &&
                          !_vm.disabled &&
                          !_vm.apprDisabled &&
                          _vm.breakdowned,
                        rows: 9,
                        label: "추가 조치 사항",
                        name: "remark",
                      },
                      model: {
                        value: _vm.data.remark,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "remark", $$v)
                        },
                        expression: "data.remark",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    staticStyle: { "padding-top": "25px !important" },
                  },
                  [
                    _c("c-upload", {
                      attrs: {
                        isPhoto: true,
                        editable:
                          _vm.editable &&
                          !_vm.disabled &&
                          !_vm.apprDisabled &&
                          _vm.breakdowned,
                        attachInfo: _vm.attachInfo,
                        label: "설비 고장사진",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _vm.editable && !_vm.disabled && !_vm.apprDisabled
                  ? _c("c-btn", {
                      attrs: {
                        url: _vm.saveUrl,
                        isSubmit: _vm.isSave,
                        param: _vm.data,
                        mappingType: "PUT",
                        label: "저장",
                        icon: "save",
                      },
                      on: {
                        beforeAction: _vm.saveData,
                        btnCallback: _vm.saveCallback,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }